html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  /* background: linear-gradient(#2d2f47, #395475, #52e1cc); */
  background: linear-gradient(#282a3f, #395374);

  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@font-face {
  font-family: "HGSoftGGothicssi Pro";
  src: url("../assets/fonts/HGSoftGGothicssi_Pro 99g.otf") format("truetype");
}
